<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-center" :class="{'pointer': props.item.has_commission}" @click="rowAction()">
      {{props.item.id}}
    </td>   
    <td class="justify-center" :class="{'pointer': props.item.has_commission}" @click="rowAction()">
      <strong>{{props.item.person.name}}</strong>
    </td>
    <td class="justify-center" :class="{'pointer': props.item.has_commission}" @click="rowAction()">
      <span v-if="props.item.has_commission">Sim</span>
      <span v-else class="finance_status"> Não </span>
    </td>
    <td class="justify-center" :class="{'pointer': props.item.has_commission}" style="text-align: center;" @click="rowAction()">
      <span v-if="props.item.has_commission">
        <span v-if="props.item.type == 1">Porcentagem</span>
        <span v-if="props.item.type == 2">Valor Fixo</span>
        <span v-if="props.item.type == 3">Por tempo</span>
      </span>
      <span v-else> --- </span>
    </td>
    <td class="justify-center" :class="{'pointer': props.item.has_commission}" @click="rowAction()">
      <strong v-if="props.item.has_commission" style="font-size: 16px;">{{props.item.amount | money}}</strong>
      <span v-else> --- </span>
    </td> 
    <td class="justify-center" :class="{'pointer': props.item.has_commission}" @click="rowAction()">
      <strong v-if="props.item.has_commission" style="font-size: 16px;">{{props.item.percentage}}%</strong>
      <span v-else> --- </span>
    </td>
    <td class="justify-center" :class="{'pointer': props.item.has_commission}" style="text-align: center;" @click="rowAction()">
      <span v-if="props.item.has_commission">
        <span v-if="props.item.parceled == 0">Não</span>
        <span v-if="props.item.parceled == 1">Sim</span>
        <span v-if="props.item.parceled == 2">Sim, vinculado aos recebimentos do processo</span>
      </span>
      <span v-else> --- </span>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'BusinessPersons',
    methods: {
      rowAction: function () {
        if (this.props.item.has_commission) {
          this.props.props.expanded = !this.props.props.expanded
        }
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
.finance_status {
  border-width: 1px;
  border-style: solid;
  padding: 2px 5px;
}
.canceled {
  color: grey;
  background: #eceff1;
}
.unpaid {
  color: #ff6d00;
  background: #f0f4c3;
}
.paid {
  color: green;
  background: #b9f6ca;
}
.parcel {
  color: white;
  background: #000;
  min-width: 44px !important;
  width: 44px !important;
}
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
.pointer {
  cursor: pointer;
}
</style>